import { Box, Tab, Tabs } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { ChangeEvent, ReactNode, useState } from 'react';
import { theme } from '../../theme';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { AddDeviceOwnerButton } from './AddDeviceOwnerButton';
import { AddDeviceOwnerModal } from './AddDeviceOwnerModal';
import DeviceOwnersTable from './DeviceOwnersTable';
// eslint-disable-next-line import/extensions
import { DeviceOwnerStatus } from './enum/DeviceOwnerKeyMaps.enum';
import { ProgressComponentProps } from '../../utils/rt';
import { BackgroundProgressBar } from '../shared/BackgroundProgressBar';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  status: (typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus];
}
function TabPanel({ status, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`device-owner-tabpanel-${index}`}
      aria-labelledby={`device-owner-tab-${index}`}
      {...other}
    >
      {value === index && <DeviceOwnersTable ownerStatus={status} />}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `device-owner-tab-${index}`,
    'aria-controls': `device-owner-tabpanel-${index}`,
  };
}

export interface DeviceOwnersProps extends ProgressComponentProps {}

export default function DeviceOwners(props: DeviceOwnersProps) {
  const { progressEvent } = props;
  const [showAddDeviceOwnerModal, setShowAddDeviceOwnerModal] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [ownerStatus, setOwnerStatus] = useState<(typeof DeviceOwnerStatus)[keyof typeof DeviceOwnerStatus]>(DeviceOwnerStatus.ENROLLED);

  const handleTabChange = (_event: ChangeEvent<HTMLButtonElement>, newTab: number) => {
    setTab(newTab);
    if (newTab === 0) {
      setOwnerStatus(DeviceOwnerStatus.ENROLLED);
    }
    if (newTab === 1) {
      setOwnerStatus(DeviceOwnerStatus.PENDING);
    }
    if (newTab === 2) {
      setOwnerStatus(DeviceOwnerStatus.UNENROLLED);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <KwContainer id={`device-owner-table-container-${ownerStatus}`}>
        <BackgroundProgressBar progressEvent={progressEvent} />
        <AddDeviceOwnerButton onClick={() => setShowAddDeviceOwnerModal(true)} />
        <AddDeviceOwnerModal
          isOpen={showAddDeviceOwnerModal}
          onClose={() => setShowAddDeviceOwnerModal(false)}
          setOwnerStatus={setOwnerStatus}
          setTab={setTab}
        />
        <StyledTabContainer>
          <StyledTabs value={tab} onChange={handleTabChange} aria-label="Enrolled/Pending/Unenrolled Device Owners tabs">
            <StyledTab label="Enrolled" {...a11yProps(0)} />
            <StyledTab label="Pending" {...a11yProps(1)} />
            <StyledTab label="Unenrolled" {...a11yProps(2)} />
          </StyledTabs>
        </StyledTabContainer>
        <TabPanel value={tab} index={0} status={ownerStatus} />
        <TabPanel value={tab} index={1} status={ownerStatus} />
        <TabPanel value={tab} index={2} status={ownerStatus} />
      </KwContainer>
    </ThemeProvider>
  );
}

const StyledTabContainer = styled(Box)`
  line-height: 1px;
  border-bottom: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  padding: 20px 40px;
  &.Mui-selected {
    color: #000;
    font-weight: 700;
  }
`;

const StyledTabs = styled(Tabs)`
  & span.MuiTabs-indicator {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
