import { Routes, Route } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Layout from '../shared/Layout';
import { ManagedAppsInfoContext, ManagedAppsInfoHook } from './AppManagementInfoContext';
import ManagedAppsTable from './ManagedApps/ManagedAppsTable';
import ManagedAppsSearch from './AppManagementSearch';
import { AppManagementProfilePage } from './AppManagementProfilePage';
// eslint-disable-next-line import/extensions
import { AppManagementStatus, AppManagementProfileMode } from './enum/AppManagementKeyMaps.enum';
import { ProgressComponentProps } from '../../utils/rt';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import DeniedAppsTable from './DeniedApps/DeniedAppsTable';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

export type ManagedAppsProps = ProgressComponentProps;

export default function AppManagement(props: ManagedAppsProps) {
  const {
    platform,
    setPlatform,
    mode,
    setMode,
    app,
    setApp,
    searchHistory,
    setSearchHistory,
    appManagementStatus,
    setAppManagementStatus,
  } = ManagedAppsInfoHook();

  const { progressEvent } = props;
  const [tab, setTab] = useState<number>(0);

  const handleTabChange = (_event: ChangeEvent<HTMLButtonElement>, newTab: number) => {
    setTab(newTab);
    if (newTab === 0) {
      setAppManagementStatus(AppManagementStatus.MANAGED);
    }
    if (newTab === 1) {
      setAppManagementStatus(AppManagementStatus.DENIED);
    }
  };

  return (
    <ManagedAppsInfoContext.Provider
      value={{
        platform,
        setPlatform,
        mode,
        setMode,
        app,
        setApp,
        searchHistory,
        setSearchHistory,
        appManagementStatus,
        setAppManagementStatus,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <KwContainer id={`app-management-table-container-${appManagementStatus}`}>
                <StyledTabContainer>
                  <StyledTabs value={tab} onChange={handleTabChange} aria-label="Managed/Denied App Management tabs">
                    <StyledTab label="Managed Apps" />
                    <StyledTab label="Denied Apps" />
                  </StyledTabs>
                </StyledTabContainer>
                {appManagementStatus === AppManagementStatus.MANAGED ? (
                  <ManagedAppsTable progressEvent={progressEvent} />
                ) : (
                  <DeniedAppsTable progressEvent={progressEvent} />
                )}{' '}
              </KwContainer>
            }
          />
          <Route path="/search" element={<ManagedAppsSearch />} />
          <Route
            path="/add"
            element={<AppManagementProfilePage mode={AppManagementProfileMode.ADD} status={appManagementStatus} setTab={setTab} />}
          />
          <Route
            path="/edit/:managedAppId"
            element={<AppManagementProfilePage mode={AppManagementProfileMode.EDIT} status={appManagementStatus} setTab={setTab} />}
          />
        </Route>
      </Routes>
    </ManagedAppsInfoContext.Provider>
  );
}

const StyledTabContainer = styled(Box)`
  line-height: 1px;
  border-bottom: 1px solid ${props => props.theme.palette.greyOverride[300]};
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  padding: 20px 40px;
  &.Mui-selected {
    color: #000;
    font-weight: 700;
  }
`;

const StyledTabs = styled(Tabs)`
  & span.MuiTabs-indicator {
    background-color: ${props => props.theme.palette.accent.primary};
  }
`;
