import { ProgressComponentProps } from "../../utils/rt";
import './BackgroundProgressBar.css';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';

export interface BackgroundProgressBarProps extends ProgressComponentProps {
}

function iconForStatus(status: string) {
  switch (status) {
    case 'success':
      return <DoneIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningAmberIcon />;
    case 'in-progress':
      return <SyncIcon className="rotating" />;
    default:
      return <InfoIcon />;
  }
}

export function BackgroundProgressBar(props: BackgroundProgressBarProps) {
  const { progressEvent } = props;

  return (progressEvent?.status &&
    <div className="bg-progress">
      <div className="bg-progress-bar">
        <div className="bg-progress-bar-fill" style={{ width: `${(progressEvent.progressValue / progressEvent.progressMax) * 100}%` }}></div>
      </div>
      <div className="bg-progress-body">
        <div className="bg-progress-icon">{iconForStatus(progressEvent.status)}</div>
        <div className="bg-progress-text">
          <div className="bg-progress-message">{progressEvent.message}</div>
          <div className="bg-progress-description">{progressEvent.description}</div>
        </div>
      </div>
    </div>
  )
}