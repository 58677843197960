import {
  AssignmentOutlined as RulesIcon,
  PhoneAndroidOutlined as DevicesIcon,
  DashboardOutlined as DashboardIcon,
  Menu as MenuIcon,
  PersonOutlineOutlined as DeviceOwnersIcon,
  VerifiedUserOutlined as PoliciesIcon,
  GridViewOutlined as AppsIcon,
  NotificationsOutlined as NotificationsIcon,
  Devices as AppManagementIcon,
  ReportOutlined as RiskIndicatorsIcon,
} from '@mui/icons-material';
import { Avatar, Box, Divider, IconButton, ListItemIcon, MenuItem, MenuList, Toolbar, Typography } from '@mui/material';
import { css, styled, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KwAppBar } from '../shared/AppBar';
import { drawerWidth, DashboardDrawer } from './Drawer';
import { NavListItemText } from './ListItemText';
import { Link } from '../../Link';
import { theme } from '../../theme';
import SettingsDropDownMenu from '../Settings/SettingsDropDownMenu';
import { DeviceProfileHeader } from '../Devices/DeviceProfileHeader';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { PolicyProfileHeader } from '../Policy/PolicyProfileHeader';
import { AppManagementSearchHeader } from '../AppManagement/AppManagementSearchHeader';
import { LaunchDarklyFlags } from '../../launch-darkly';
import RiskIndicatorProfileHeader from '../RiskIndicators/RiskIndicatorProfileHeader';

interface NavigationProps {
  title: string;
  children: JSX.Element;
}

export function Navigation({ title, children }: NavigationProps) {
  const [open, setOpen] = useState(true);
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const flags: LaunchDarklyFlags['flags'] = useFlags();

  const navItems = [
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_DASHBOARD),
      path: '/dashboard',
      label: 'Dashboard',
      icon: <DashboardIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_DEVICES),
      path: '/devices',
      label: 'Devices',
      icon: <DevicesIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_DEVICE_OWNERS),
      path: '/device-owners',
      label: 'Device Owners',
      icon: <DeviceOwnersIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_APPLICATIONS),
      path: '/applications',
      label: 'Applications',
      icon: <AppsIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_RULES),
      path: '/rules',
      label: 'Rules',
      icon: <RulesIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_POLICIES),
      path: '/policies',
      label: 'Policies',
      icon: <PoliciesIcon />,
    },
    {
      hasPermissions: flags.managedApps && verifyAuthPermission(AuthPermissions.MENU_MANAGED_APPS),
      path: '/app-management',
      label: 'App Management',
      icon: <AppManagementIcon />,
    },
    {
      hasPermissions: verifyAuthPermission(AuthPermissions.MENU_RISK_INDICATORS),
      path: '/risk-indicators',
      label: 'Risk Indicators',
      icon: <RiskIndicatorsIcon />,
    },
  ];

  const location = useLocation();

  const CustomHeader = () => {
    if (location.pathname.match(/\/policies\/create/g)) {
      return <StyledHeaderText>Create Policy</StyledHeaderText>;
    }

    if (location.pathname.match(/\/policies\/edit/g)) {
      return <StyledHeaderText>Edit Policy</StyledHeaderText>;
    }

    if (location.pathname.match(/\/policies\/view/g)) {
      return <PolicyProfileHeader />;
    }

    if (location.pathname.includes('/device-details')) {
      const deviceName = new URLSearchParams(location.search).get('deviceName') ?? title;
      const deviceId = new URLSearchParams(location.search).get('deviceId');

      return <DeviceProfileHeader deviceName={deviceName} deviceId={deviceId} />;
    }

    if (location.pathname.includes('/application-details')) {
      const appName = new URLSearchParams(location.search).get('appName');

      return <StyledHeaderText>{appName}</StyledHeaderText>;
    }

    if (location.pathname.match(/\/app-management\/(add|search|edit)/g)) {
      return <AppManagementSearchHeader />;
    }

    if (location.pathname.match(/\/risk-indicators\/edit/g)) {
      return <RiskIndicatorProfileHeader />;
    }

    if (location.pathname.includes('/organization-mismatch-error')) {
      return <StyledHeaderText />;
    }

    return <StyledHeaderText>{title}</StyledHeaderText>;
  };

  const { isAuthenticated, isLoading, user } = useAuth0();

  const handleSettingsDropDownMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setSettingsMenuAnchorEl(e.currentTarget);
  };

  const handleSettingsDropDownMenuClose = () => {
    setSettingsMenuAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBox>
        <StyledAppBar position="absolute" open={open}>
          <StyledToolbar>
            <ToggleIconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} open={open}>
              <StyledMenuIcon />
            </ToggleIconButton>
            <CustomHeader />
            <StyledNotificationsIcon />
            <StyledAvatar
              alt={isAuthenticated && !isLoading && user ? user.name : ''}
              src={isAuthenticated && !isLoading && user ? user.picture : ''}
              onClick={e => handleSettingsDropDownMenuClick(e)}
              data-testid="user-avatar"
            />
            <SettingsDropDownMenu anchorEl={settingsMenuAnchorEl} handleClose={handleSettingsDropDownMenuClose} />
          </StyledToolbar>
        </StyledAppBar>

        <StyledDrawer open={open}>
          <StyledToolbar component={Link} href="/">
            <img src="/logo/Quokka_Logo_RGB_wht.svg" alt="quokka-logo" width={128} height={56} />
          </StyledToolbar>
          <StyledDivider />
          <nav>
            <StyledMenuList>
              {navItems.map(
                ({ path, label, icon, hasPermissions }) =>
                  hasPermissions && (
                    <StyledMenuItem current={location.pathname.startsWith(path)} key={path}>
                      <StyledLink to={path} href={path}>
                        <StyledListItemIcon>{icon}</StyledListItemIcon>
                        <NavListItemText primary={label} />
                      </StyledLink>
                    </StyledMenuItem>
                  ),
              )}
            </StyledMenuList>
          </nav>
        </StyledDrawer>

        <StyledMain>
          {/** placeholder to offset the top of the container */}
          <StyledToolbar />
          {children}
        </StyledMain>
      </StyledBox>
    </ThemeProvider>
  );
}

/** styles */
const StyledAvatar = styled(Avatar)`
  height: 35px;
  width: 35px;
  background-color: rgb(183, 194, 248);
  & img {
    margin-left: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  font-size: 35px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  color: black;
  padding-left: 0;
  padding-right: 0;
  & img {
    margin-left: 45px;
  }
` as typeof Toolbar;

const StyledMenuList = styled(MenuList)`
  padding-top: 16px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: black;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  color: white;
  min-width: 0;
  padding-right: 11px;
`;

const StyledLink = styled(Link)`
  display: flex;
  padding: 20px 18px 17px 18px;
  width: 100%;
  text-decoration: none;
`;

const selectedNavStyles = props => css`
  .MuiSvgIcon-root {
    fill: ${props.theme.palette.accent.redOrange};
  }
  .MuiListItemText-root {
    color: ${props.theme.palette.accent.redOrange};
  }
  &::before {
    content: '';
    width: 6px;
    height: 61px;
    background-color: ${props.theme.palette.accent.redOrange};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'current',
})<{ current: boolean }>`
  padding: 0;
  &:hover {
    ${selectedNavStyles}
  }
  ${props => props.current && selectedNavStyles(props)}
`;

const StyledHeaderText = styled(Typography)`
  flex-grow: 1;
  line-height: 45px;
  color: ${props => props.theme.palette.text.secondary};
  // shorten leading trim on leftside to offset default whitespace
  margin-left: -4px;
` as typeof Typography;

// @ts-ignore
StyledHeaderText.defaultProps = {
  component: 'h1',
  variant: 'h1Regular',
};

const StyledBox = styled(Box)`
  display: grid;

  grid-template:
    'header            header' fit-content(100%)
    'drawer             main' auto
    / fit-content(100%) auto;

  background-color: white;
  height: 100vh;
  overflow: auto;
`;

const StyledDivider = styled(Divider)`
  border-color: white;
`;

const ToggleIconButton = styled(IconButton)<{ open: boolean }>`
  ${props => (props.open ? 'display: none;' : '')};
  margin-right: 31px;
  width: 55px;
`;

const StyledAppBar = styled(KwAppBar)`
  && {
    grid-area: header;
    padding: ${props => props.open && '0px 30px'};
    z-index: 1;
  }
`;

const StyledDrawer = styled(DashboardDrawer)`
  grid-area: drawer;
  width: ${drawerWidth}px;
  box-shadow: 4px 0px 4px rgb(0, 0, 0, 0.25);
  z-index: 1;
`;

StyledDrawer.defaultProps = {
  variant: 'permanent',
};

const StyledMain = styled((props: any) => <Box {...props} component="main" />)`
  grid-area: main;
  overflow-y: hidden;
`;
