import { Provider as RollbarProvider } from '@rollbar/react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HashRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { theme } from '../theme';
import { createEmotionCache } from '../createEmotionCache';
import { useLocalStorage } from '../utils/useLocalStorage';
import { socket } from '../utils/rt';
import { AuthPermissions } from '../auth/AuthPermissions';
import { verifyAuthPermission } from '../auth/VerifyAuthPermission';

import Index from './index';
import Applications from './Applications';
import ApplicationDetails from './Applications/ApplicationDetails';
import Devices from './Devices';
import DeviceDetails from './Devices/DeviceDetails';
import DeviceOwners from './DeviceOwners';
import Layout from './shared/Layout';
import Policies from './Policy';
import { Rules } from './Rules';
import { Settings } from './Settings';
import AppManagement from './AppManagement';
import RiskIndicators from './RiskIndicators';
import { Dashboard } from './Dashboard';
import { jwtPayload } from '../auth';
import { verifyAuthExpiration } from '../auth/VerifyAuthExpiration';
import Register from './Register';
import OktaTroubleshooting from './OktaTroubleshooting';
import { OrgProvider } from './Organization/org-provider';
import OrgMismatchError from './shared/OrgMismatchError';

// extend ProviderProps for @rollbar/react Provider (RollbarProvider) to avoid overload error (bug)
declare module '@rollbar/react' {
  interface ProviderProps {
    children: ReactNode;
  }
}

export default function App() {
  ReactGA.initialize([
    {
      trackingId: process.env.GA4_ID,
    },
  ]);

  const rollbarConfig = {
    enabled: process.env.NODE_ENV === 'production',
    accessToken: '24a2bce8fad747d094182c5682b141dd',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
    },
    autoInstrument: {
      network: true,
      networkResponseHeaders: true,
      networkResponseBody: true,
      networkRequestBody: true,
    },
  };

  const cache = createEmotionCache();

  const ProtectedRoute = ({ authPermission }: { authPermission: string | string[] }) => {
    if (Array.isArray(authPermission)) {
      for (const permission of authPermission) {
        if (verifyAuthPermission(permission)) {
          return <Outlet />;
        }
      }
    } else if (verifyAuthPermission(authPermission)) {
      return <Outlet />;
    }
    return <Navigate to={'/dashboard'} />;
  };
  const [accessToken = '', updateAccessToken] = useLocalStorage('accessToken', '');

  const RequiresLogin = () => {
    // check and initialize auth and LaunchDarkly
    const checkAuthAndinitialize = async () => {
      // only proceed with auth checks if the auth0 client is initialized (isLoading === false)
      if (!auth0.isLoading) {
        if (!accessToken) {
          // user has no existing session go ahead and create a new one
          try {
            // get access token from auth0 and put into localstorage
            const obj = await auth0.getAccessTokenSilently();
            updateAccessToken(obj);
          } catch (err) {
            console.warn(err);
          }
        } else {
          // existing session detected, confirm if not expired
          verifyAuthExpiration(jwtPayload(accessToken), auth0);
        }

        if (!auth0.user) {
          // when an auth0.logout() occurs it removes the user from session, thus
          // we need to bring them back to login page.
          auth0.loginWithRedirect();
        } else {
          if (statusEvent !== 'logged in') {
            socket.emit('login', accessToken);
          }
        }
      }
    };
    checkAuthAndinitialize();
    return <Outlet />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const auth0 = useAuth0();
  const ldClient = useLDClient();
  // this is used to determine whether the app is fully initialized
  // auth & LD are ready to go.

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [statusEvent, setStatusEvent] = useState({});
  const [progressEvent, setProgressEvent] = useState({ progressValue: 0, progressMax: 0, message: '', description: '', status: '' });

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onStatusEvent(value) {
      console.log(`status: ${JSON.stringify(value)}`);
      setStatusEvent(value);
    }

    function onProgressEvent(value) {
      console.log(`progress: ${JSON.stringify(value)}`);
      setProgressEvent(value);
      if (value.status !== 'in-progress') {
        setTimeout(() => setProgressEvent({ progressValue: 0, progressMax: 0, message: '', description: '', status: '' }), 3000);
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('status', onStatusEvent);
    socket.on('progress', onProgressEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('status', onStatusEvent);
      socket.off('progress', onProgressEvent);
    };
  }, []);
  if (ldClient.waitUntilReady)
    return (
      <RollbarProvider config={rollbarConfig}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <HelmetProvider>
                <Helmet>
                  {/* PWA primary color */}
                  <meta name="theme-color" content={theme.palette.accent.primary} />
                </Helmet>
                <CssBaseline />
                <Router>
                  <OrgProvider accessToken={accessToken}>
                    <Routes>
                      <Route element={<RequiresLogin />}>
                        <Route path="/" element={<Index />} />
                        <Route element={<Layout />}>
                          <Route path="/dashboard" element={<Dashboard progressEvent={progressEvent} />} />
                          <Route path="/devices" element={<Devices progressEvent={progressEvent} />} />
                          <Route path="/device-details" element={<DeviceDetails progressEvent={progressEvent} />} />
                          <Route element={<ProtectedRoute authPermission={AuthPermissions.LIST_APP_VERSIONS} />}>
                            <Route path="/applications" element={<Applications progressEvent={progressEvent} />} />
                          </Route>
                          <Route element={<ProtectedRoute authPermission={AuthPermissions.READ_APP} />}>
                            <Route
                              path="/applications/application-details"
                              element={<ApplicationDetails progressEvent={progressEvent} />}
                            />
                          </Route>
                          <Route path="/rules" element={<Rules progressEvent={progressEvent} />} />
                          <Route path="/device-owners" element={<DeviceOwners progressEvent={progressEvent} />} />
                          <Route path="/settings" element={<Settings progressEvent={progressEvent} />} />
                          <Route path="/organization-mismatch-error" element={<OrgMismatchError />} />
                        </Route>
                        {/* managed apps, policies, risk indicators layout rely on respective context variables - rendered separately */}
                        <Route path="/app-management/*" element={<AppManagement progressEvent={progressEvent} />} />
                        <Route path="/policies/*" element={<Policies progressEvent={progressEvent} />} />
                        <Route
                          element={<ProtectedRoute authPermission={[AuthPermissions.LIST_RISK_INDICATORS, AuthPermissions.SYSTEM_ADMIN]} />}
                        >
                          <Route path="/risk-indicators/*" element={<RiskIndicators />} />
                        </Route>
                      </Route>
                      <Route path="/register" element={<Register />} />
                      <Route path="/okta-troubleshooting" element={<OktaTroubleshooting />} />
                    </Routes>
                  </OrgProvider>
                </Router>
              </HelmetProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </RollbarProvider>
    );
}
