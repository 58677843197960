import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import Typography from '@mui/material/Typography';
import { PermDeviceInformation } from '@mui/icons-material';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';

interface IProps {
  permissionsRequested: {
    name: string;
    protectionLevel: string;
  }[];
}

export default function PermissionsRequestedTable({ permissionsRequested }: IProps) {
  if (permissionsRequested == null || permissionsRequested.length === 0) {
    return (
      <StyledCenteredContainer>
        <StyledPermDeviceInformationIcon />
        <StyledNoPermissionsTypography variant="h4Medium">No requested permissions found</StyledNoPermissionsTypography>
      </StyledCenteredContainer>
    );
  }

  return (
    <KwTable aria-label="customized table">
      <KwTableBody>
        {permissionsRequested.map(permission => {
          return (
            <KwTableRow key={permission.name}>
              <KwTableCell>
                {permission.protectionLevel === 'Important' && <StyledWarningIcon color={'error'} />}
                {permission.name}
              </KwTableCell>
            </KwTableRow>
          );
        })}
      </KwTableBody>
    </KwTable>
  );
}

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.base.grey,
  '&.MuiSvgIcon-colorError': {
    color: theme.palette.error.main,
  },
  display: 'inline-block',
  margin: '5px 5px -6px 0',
}));

const StyledCenteredContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

const StyledPermDeviceInformationIcon = styled(PermDeviceInformation)`
  font-size: 48px;
`;

const StyledNoPermissionsTypography = styled(Typography)`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
`;
