import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.RT_SERVICE_URL || 'http://localhost:8091';

export const socket = io(URL, { transports: ["websocket"] });

export type ProgressComponentProps = {
  progressEvent: {
    progressValue: number;
    progressMax: number;
    message: string;
    description: string;
    status: string;
  };
};
