import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { useEffect } from 'react';
import { Card, CardProps } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import { useAppVersions } from './hooks';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { TableRowLink } from '../shared/TableRowLink';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { PageLoader } from '../../PageLoader';
import { useApplicationSortParams } from './useApplicationSortParams';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { extractPermissions, Permissions } from './ApplicationDetails';

interface IProps {
  accessToken: string;
  appName: string;
  os: string;
  appId: string;
  appVersions: any;
  currentVersion?: string;
}

export const AppVersionsTable = ({ accessToken, appName, os, appId, appVersions, currentVersion }: IProps) => {
  const {
    appVersionOrder,
    toggleAppVersionOrder,
    dateAnalyzedOrder,
    toggleDateAnalyzedOrder,
    numberOfDevicesOrder,
    toggleNumberOfDevicesOrder,
    numberOfIorsOrder,
    toggleNumberOfIorsOrder,
  } = useApplicationSortParams();

  const { page, pageSize, setPage, handleChangePage, handleChangeRowsPerPage } = useKwPagination(10);

  const formatDate = (timestamp: Date): string => {
    return dayjs(timestamp).format('MM/DD/YY');
  };

  useEffect(() => {
    /* 
      HACK - to get correct default sort order (Issues column DESC)
      in dev environment, using react strict mode, components re-render an extra time which executes toggle twice
      in production, components only render once so we need to execute toggle twice
     */
    toggleDateAnalyzedOrder();
    if (process.env.NODE_ENV !== 'development') {
      toggleDateAnalyzedOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { apps, appsDataError } = useAppVersions(
    '/application/versions',
    {
      page: String(page),
      pageSize: String(pageSize),
      appVersionOrder,
      numberOfDevicesOrder,
      numberOfIorsOrder,
      dateAnalyzedOrder,
      appId,
    },
    accessToken,
  );

  if (appsDataError) {
    console.error(appsDataError);
    return <div>failed to load</div>;
  }

  if (!apps) {
    return <PageLoader />;
  }

  const versionTableHeaders = [
    { title: 'Version', onClick: toggleAppVersionOrder, sortValue: appVersionOrder },
    {
      title: 'Date Analyzed',
      onClick: toggleDateAnalyzedOrder,
      sortValue: dateAnalyzedOrder,
    },
    {
      title: 'Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: 'Issues',
      onClick: toggleNumberOfIorsOrder,
      sortValue: numberOfIorsOrder,
    },
    {
      title: 'Permissions',
      onClick: toggleAppVersionOrder,
      sortValue: appVersionOrder,
    },
  ];

  const otherVersions = apps.filter(({ os: avOs, applicationVersion }) => {
    return avOs === os && applicationVersion !== currentVersion;
  });

  const total = otherVersions.length;
  const totalPageCount = Math.ceil(total / pageSize);

  if (otherVersions.length === 0) {
    return (
      <div>
        <StyledCard>
          <StyledCardTitle>Other versions of {appName}</StyledCardTitle>
          <StyledCenteredContainer>
            <StyledLayersIcon />
            <StyledNoVersionsTypography variant="h4Medium">No other versions observed</StyledNoVersionsTypography>
          </StyledCenteredContainer>
        </StyledCard>
      </div>
    );
  }

  const permissionCounts = getPermissionCounts(appVersions);

  return (
    <div>
      <StyledTableTitle>Other versions of {appName}</StyledTableTitle>
      {total > 10 ? (
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
        </KwTablePaginationWrapper>
      ) : null}
      <KwTable aria-label={`Version table of application ${appName}`}>
        <KwTableHead>
          <KwTableRow>
            {versionTableHeaders.map(({ title, sortValue, onClick }) => (
              <KwColumnSortHeader
                key={title}
                title={title}
                onClick={() => {
                  onClick();
                  setPage(0);
                }}
                sortValue={sortValue}
              />
            ))}
          </KwTableRow>
        </KwTableHead>
        <KwTableBody>
          {otherVersions.map(
            ({
              applicationId,
              applicationVersion,
              deviceCount,
              policyViolations,
              riskIndicatorCount,
              riskIndicators,
              updatedDate,
              title,
            }) => {
              const permissionCount = permissionCounts.find(permission => permission.version === applicationVersion)?.count ?? 0;
              return (
                <TableRowLink
                  ariaLabel={`${title} row`}
                  key={applicationId + applicationVersion + String(riskIndicatorCount)}
                  uniqueId={applicationId}
                  href={`/applications/application-details?appId=${applicationId}&appName=${title}&appVersion=${applicationVersion}&os=${os}`}
                >
                  <KwTableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledLinkText> {applicationVersion}</StyledLinkText>
                      {policyViolations && <StyledWarningIcon color="error" />}
                      {policyViolations && <StyledWarningTagText>Policy Violation</StyledWarningTagText>}
                    </div>
                  </KwTableCell>
                  <KwTableCell>{formatDate(updatedDate)}</KwTableCell>
                  <KwTableCell>{deviceCount}</KwTableCell>
                  <KwTableCell>{riskIndicators.length}</KwTableCell>
                  <KwTableCell>{permissionCount}</KwTableCell>
                </TableRowLink>
              );
            },
          )}
        </KwTableBody>
      </KwTable>
      {total > 10 ? (
        <KwTablePaginationWrapper>
          <KwRowsPerPage
            rowsPerPageOptions={[10, 20, 50, 100]}
            pageSize={pageSize}
            page={page}
            totalNumberOfEntries={total}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
        </KwTablePaginationWrapper>
      ) : null}
    </div>
  );
};
/** styles */

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableTitle = styled('h1')`
  height: 46px;
  width: 100%;
  padding: 12px 16px 16px 0;
  font-size: 16px;
  color: #333333;
  margin: 0;
  background-color: ${props => props.theme.palette.greyOverride[50]};
`;

const StyledCenteredContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const StyledLayersIcon = styled(LayersOutlinedIcon)`
  font-size: 48px;
`;

const StyledNoVersionsTypography = styled(Typography)`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
`;

const StyledLinkText = styled('div')`
  &:hover {
    text-decoration: underline;
  }
`;

type StyledCardProps = {
  width?: string;
} & CardProps;

const StyledCard = styled((props: StyledCardProps) => <Card {...props} />)<StyledCardProps>`
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  padding: 8px;
  margin-right: 10px;
  width: ${({ width }) => width || '100%'};
`;

const StyledCardTitle = styled('h1')`
  font-size: 16px;
  color: #333;
`;

type AppVersion = {
  permissions: Permissions;
  version: string;
};

type PermissionCount = {
  version: string;
  count: number;
};

const getPermissionCounts = (appVersions: AppVersion[]): PermissionCount[] => {
  const permissionCounts: { [version: string]: number } = {};

  appVersions.forEach(appVersion => {
    const permissions = extractPermissions(appVersion.permissions);
    const { version } = appVersion;
    permissions.forEach(permission => {
      permissionCounts[version] = (permissionCounts[version] || 0) + 1;
    });
  });

  return Object.entries(permissionCounts).map(([version, count]) => ({ version, count }));
};

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 0 0 10px;
`;

const StyledWarningTagText = styled('div')`
  color: #df2e21;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  text-align: left;
  margin-left: 5px;
`;
