import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Link, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { RiskIndicatorInfoContext } from './RiskIndicatorInfoContext';
import { sendHttpRequest } from '../../utils/network.service';
import { checkPermissions } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';
// eslint-disable-next-line import/extensions
import { RiskIndicatorProfileMode } from './enum/RiskIndicatorProfileMode.enum';

export default function RiskIndicatorProfilePage() {
  const [remediation, setRemediation] = useState('');
  const [weight, setWeight] = useState('');
  const navigate = useNavigate();
  const { activeRiskIndicator, activeOrganization, mode } = useContext(RiskIndicatorInfoContext);
  const { enqueueSnackbar } = useSnackbar();

  const hasUpdatePermission = checkPermissions([AuthPermissions.UPDATE_RISK_INDICATOR]);

  const createMode = mode === RiskIndicatorProfileMode.CREATE;

  const updateRiskIndicator = async () => {
    if (!hasUpdatePermission) return;
    // only send updates if changes were made
    if (remediation === '' && weight === '') return;

    const path = createMode
      ? `${process.env.RISK_SCORE_SERVICE_URL}/risk-score/organization-indicators`
      : `${process.env.RISK_SCORE_SERVICE_URL}/risk-score/organization-indicators/${activeRiskIndicator.id}`;

    const body = createMode
      ? {
          riskIndicatorId: activeRiskIndicator.id,
          organizationId: activeOrganization,
        }
      : { ...activeRiskIndicator };

    const method = createMode ? 'POST' : 'PUT';

    if (remediation.length) Object.assign(body, { remediation: JSON.parse(remediation) });
    if (weight.length) Object.assign(body, { weight: Number(weight) });

    await sendHttpRequest({
      path,
      method,
      body,
      successMessage: `Successfully updated risk indicator: ${activeRiskIndicator.title}`,
      errorMessage: `Error updating risk indicator: ${activeRiskIndicator.title}`,
      enqueueSnackbar,
    });
    navigate('/risk-indicators');
  };

  // returns to /risk-indicators if edit page gets reloaded (state is lost)
  useEffect(() => {
    if (Object.keys(activeRiskIndicator).length === 0) navigate('/risk-indicators');
  });

  return (
    <StyledContainer>
      <StyledSplitPanel>
        <StyledBackSection>
          <KwButton variant="light" startIcon={<ArrowBack />} onClick={() => navigate('/risk-indicators')}>
            Back to Risk Indicators
          </KwButton>
        </StyledBackSection>
        <StyledDetailsSection>
          <Typography variant="h4Medium">Details</Typography>
        </StyledDetailsSection>
        <StyledRiskIndicatorInfoSection>
          {/* title */}
          <StyledRiskIndicatorInfoItem>
            <StyledRiskIndicatorInfoLabel>
              <StyledTypography>Title</StyledTypography>
            </StyledRiskIndicatorInfoLabel>
            <StyledRiskIndicatorInfoValue>
              <Typography>{activeRiskIndicator.title}</Typography>
            </StyledRiskIndicatorInfoValue>
          </StyledRiskIndicatorInfoItem>
          {/* description */}
          <StyledRiskIndicatorInfoItem>
            <StyledRiskIndicatorInfoLabel>
              <StyledTypography>Description</StyledTypography>
            </StyledRiskIndicatorInfoLabel>
            <StyledRiskIndicatorInfoValue>
              <Typography>{activeRiskIndicator.description}</Typography>
            </StyledRiskIndicatorInfoValue>
          </StyledRiskIndicatorInfoItem>
          {/* remediation */}
          <StyledRiskIndicatorInfoItem>
            <StyledRiskIndicatorInfoLabel>
              <StyledTypography>Remediation</StyledTypography>
            </StyledRiskIndicatorInfoLabel>
            <StyledRiskIndicatorInfoValue>
              <StyledTextField
                multiline
                fullWidth
                defaultValue={JSON.stringify(activeRiskIndicator.remediation, undefined, 4)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRemediation(event.target.value);
                }}
              />
            </StyledRiskIndicatorInfoValue>
          </StyledRiskIndicatorInfoItem>
          {/* weight */}
          <StyledRiskIndicatorInfoItem>
            <StyledRiskIndicatorInfoLabel>
              <StyledTypography>Weight</StyledTypography>
            </StyledRiskIndicatorInfoLabel>
            <StyledRiskIndicatorInfoValue>
              <StyledInput
                type="number"
                defaultValue={String(activeRiskIndicator.weight)}
                placeholder={String(activeRiskIndicator.weight)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWeight(event.target.value);
                }}
                inputProps={{ min: 0, max: 100 }}
              />
            </StyledRiskIndicatorInfoValue>
          </StyledRiskIndicatorInfoItem>
        </StyledRiskIndicatorInfoSection>
      </StyledSplitPanel>
      <StyledButtonsContainer>
        <StyledLink onClick={() => navigate('/risk-indicators')}>Cancel</StyledLink>
        <StyledKwButton variant="filled" onClick={updateRiskIndicator}>
          Save
        </StyledKwButton>
      </StyledButtonsContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled(KwContainer)`
  padding: 1% 3%;
` as typeof KwContainer;

const StyledSplitPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `
    'back .'
    'details .'
    'info info'`,
  gap: '20px 44px',
});

const StyledBackSection = styled('div')`
  grid-area: back;
`;

const StyledDetailsSection = styled('div')`
  grid-area: details;
`;

const StyledRiskIndicatorInfoSection = styled('div')`
  grid-area: info;
`;

const StyledRiskIndicatorInfoItem = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `
    'label value'`,
  gap: '20px 44px',
  marginTop: '16px',
});

const StyledRiskIndicatorInfoLabel = styled('div')`
  grid-area: label;
`;

const StyledRiskIndicatorInfoValue = styled('div')`
  grid-area: value;
`;

const StyledTypography = styled(Typography)`
  font-weight: 700;
`;

const StyledTextField = styled(TextField)`
  & > div {
    border-radius: 8px;
  }
`;

const StyledInput = styled(TextField)`
  & > div {
    border-radius: 8px;
    width: 100px;
  }
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  & button {
    margin-left: 24px;
  }
`;

const StyledKwButton = styled(KwButton)`
  width: 90px;
  height: 48px;
  padding: 8px 30px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  margin: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:nth-of-type(1) {
    color: #487f87;
  }
`;
