import { Routes, Route } from 'react-router-dom';
import Layout from '../shared/Layout';
// eslint-disable-next-line import/extensions
import { PolicyProfileMode } from './enum/PolicyProfileMode.enum';
import { PolicyInfoContext, PolicyInfoHook } from './PolicyInfoContext';
import { PolicyProfilePage } from './PolicyProfilePage';
import { PoliciesTable } from './PoliciesTable';
import { ProgressComponentProps } from '../../utils/rt';

export type PoliciesProps = ProgressComponentProps;

export default function Policies(props: PoliciesProps) {
  const {
    policyName,
    policyEnabled,
    policyDescription,
    policyCreatedOn,
    policyFormErrors,
    setPolicyName,
    setPolicyEnabled,
    setPolicyDescription,
    setPolicyCreatedOn,
    setPolicyFormErrors,
  } = PolicyInfoHook();

  const { progressEvent } = props;

  return (
    <PolicyInfoContext.Provider
      value={{
        policyName,
        policyEnabled,
        policyDescription,
        policyCreatedOn,
        policyFormErrors,
        setPolicyName,
        setPolicyEnabled,
        setPolicyDescription,
        setPolicyCreatedOn,
        setPolicyFormErrors,
      }}
    >
      <Routes>
        <Route element={<Layout />}>
          <Route path="create" element={<PolicyProfilePage mode={PolicyProfileMode.CREATE} />} />
          <Route path="edit/:policyId" element={<PolicyProfilePage mode={PolicyProfileMode.EDIT} />} />
          <Route path="view/:policyId" element={<PolicyProfilePage mode={PolicyProfileMode.VIEW} />} />
          <Route path="/" element={<PoliciesTable progressEvent={progressEvent} />} />
        </Route>
      </Routes>
    </PolicyInfoContext.Provider>
  );
}
