import { styled } from '@mui/material/styles';
import { StyledCardHeader } from '.';
import { PageLoader } from '../../PageLoader';

export default function DevicesCard({ total, error, isLoading }: { total: number; error: boolean; isLoading: boolean }) {
  if (error) {
    console.error(error);
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <StyledCardHeader>Devices</StyledCardHeader>
      <StyledTotalDevices>{total}</StyledTotalDevices>
    </>
  );
}

const StyledTotalDevices = styled('div')({
  fontSize: '33px',
  lineHeight: '49px',
  fontWeight: '700',
  color: '#000',
});
