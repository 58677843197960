import { Checkbox, IconButton, Link, Tooltip, Typography } from '@mui/material';
import {
  Add as AddIcon,
  Backspace as BackspaceIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  CloudOff as CloudOffIcon,
  Mail as MailIcon,
  MoreVert as MoreVertIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
  ToggleOnOutlined as ToggleOnOutlinedIcon,
  IndeterminateCheckBoxOutlined as IndeterminateCheckBoxOutlinedIcon,
} from '@mui/icons-material';
import { css, styled, ThemeProvider } from '@mui/material/styles';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Policy } from '@kw/rules-service/dist/src/policy/models/policy.model';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sendHttpRequest } from '../../utils/network.service';
import { theme } from '../../theme';
// components
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwFilterWrapper } from '../../kw-ui-components/KwFilterWrapper';
import { KwSearchInput } from '../../kw-ui-components/KwSearchInput';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
// pagination
import { KwPaginationRouter } from '../../kw-ui-components/Pagination/KwPaginationRouter';
import { KwRowsPerPage } from '../../kw-ui-components/Pagination/KwRowsPerPage';
import { useKwPagination } from '../../kw-ui-components/Pagination/useKwPagination';
import { KwTablePaginationWrapper } from '../../kw-ui-components/Pagination/KwTablePaginationWrapper';
// policies utils
import { usePoliciesSortParams } from './usePoliciesSortParams';
// child components
import { PoliciesTableAssociatedRulesPopover, PoliciesTableMorePopover } from './PoliciesTablePopovers';
import PoliciesTableToolbar from './PoliciesTableToolbar';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { getComparator } from '../../utils/getComparator';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { PageLoader } from '../../PageLoader';
import { LaunchDarklyFlags } from '../../launch-darkly';
import { ProgressComponentProps } from '../../utils/rt';
import { BackgroundProgressBar } from '../shared/BackgroundProgressBar';

interface OrgPolicy extends Policy {
  id: string;
}

export type PoliciesTableProps = ProgressComponentProps;

export function PoliciesTable(props: PoliciesTableProps) {
  const { progressEvent } = props;

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [rulesPolicyId, setRulesPolicyId] = useState(null);
  const [rulesAnchorEl, setRulesAnchorEl] = useState(null);
  const [moreButtonPolicyId, setMoreButtonPolicyId] = useState(null);
  const [moreButtonAnchorEl, setMoreButtonAnchorEl] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const flags: LaunchDarklyFlags['flags'] = useFlags();

  let path = `/rule/policies/list`;
  if (flags?.evalService) {
    path = path.replace('/rule/', '/eval/');
  }

  const { data: policiesData, error: policiesError, mutate } = useFetchService(path, accessToken);
  const isLoading = !policiesData && !policiesError;

  const { policyNameOrder, numberOfDevicesOrder, togglePolicyNameOrder, toggleNumberOfDevicesOrder, orderBy, setOrderBy } =
    usePoliciesSortParams();

  const { page, pageSize, handleChangePage, handleChangeRowsPerPage, setPage } = useKwPagination(10);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const filteredPolicies = useMemo(() => {
    const policies = policiesData ? policiesData.results : [];
    return searchQuery.length
      ? policies.filter(
          (item: OrgPolicy) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.policyRules.some(policyRule => policyRule.rule.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            String(item.numberOfDevices).includes(searchQuery.toLowerCase()),
          // item.deviceGroups.some(group => group.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        )
      : policies;
  }, [policiesData, searchQuery]);

  const total = filteredPolicies.length;
  const totalPageCount = Math.ceil(total / pageSize);

  // resets selected if search bar is updated
  useEffect(() => {
    setSelected([]);
  }, [searchQuery]);

  const tableHeaders = [
    {
      title: 'Policy Name',
      onClick: togglePolicyNameOrder,
      sortValue: policyNameOrder,
    },
    {
      title: 'Associated Rules',
      onClick: () => {},
      sortValue: null,
    },
    {
      title: '# of Devices',
      onClick: toggleNumberOfDevicesOrder,
      sortValue: numberOfDevicesOrder,
    },
    {
      title: 'Actions',
      onClick: () => {},
      sortValue: null,
    },
    {
      title: 'Enable',
      onClick: () => {},
      sortValue: null,
    },
    {
      // more button column
      title: '',
      onClick: () => {},
      sortValue: null,
    },
  ];

  // column sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (policyNameOrder) {
      setOrderBy('name');
    }

    if (numberOfDevicesOrder) {
      setOrderBy('numberOfDevices');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [policyNameOrder, numberOfDevicesOrder, order, orderBy, setOrderBy]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, setPage]);

  // create new policy button
  const navigate = useNavigate();

  const createNewPolicy = () => {
    navigate('/policies/create');
  };

  const selectPolicy = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const visiblePolicies = filteredPolicies.slice(page * pageSize, (page + 1) * pageSize);

  const selectAllPolicies = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedPolicies = visiblePolicies.map((p: OrgPolicy) => p.id);
      setSelected(newSelectedPolicies);
      return;
    }
    setSelected([]);
  };

  // enabled icon
  const updatePolicy = async (id: string, policyEnabled: boolean, ldFlags?: LaunchDarklyFlags['flags']) => {
    const updateAction = policyEnabled ? 'disabled' : 'enabled';

    let updatePath = `${process.env.RULES_SERVICE_URL}/rule/policies/update`;
    if (ldFlags?.evalService) {
      updatePath = updatePath.replace('/rule/', '/eval/');
    }

    await sendHttpRequest({
      path: updatePath,
      method: 'POST',
      body: {
        id,
        enabled: !policyEnabled,
      },
      successMessage: `Successfully ${updateAction} policy`,
      errorMessage: 'Error updating policy',
      enqueueSnackbar,
    });

    mutate();
  };

  const handlePopoverClick = (
    setId: React.Dispatch<number>,
    setAnchorEl: React.Dispatch<HTMLElement>,
    event: { currentTarget: HTMLButtonElement | HTMLAnchorElement } = null,
    policyId: number = null,
  ) => {
    if (!event && !policyId) {
      setId(null);
      setAnchorEl(null);
    } else {
      setId(policyId);
      setAnchorEl(event.currentTarget);
    }
  };

  if (policiesError) {
    console.error(policiesError);
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <ThemeProvider theme={theme}>
        <KwContainer>
          <BackgroundProgressBar progressEvent={progressEvent} />
          <StyledPolicyTableInfoWrapper>
            <span>
              Policies are comprised of one or many rules and are associated with actions that are performed when the rule(s) are not met
              for a device or group of devices.
            </span>
            {/* add new policy button */}
            <StyledKwButton startIcon={<AddIcon />} variant="filled" onClick={createNewPolicy}>
              Create Policy
            </StyledKwButton>
          </StyledPolicyTableInfoWrapper>
          <KwFilterWrapper>
            <StyledTableWrapper>
              <KwSearchInput value={searchQuery} onChange={setSearchQuery} placeholder="Search policies" />
              <KwTablePaginationWrapper>
                <KwRowsPerPage
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pageSize={pageSize}
                  page={page}
                  totalNumberOfEntries={total}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {total > 10 ? <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} /> : null}
              </KwTablePaginationWrapper>
              {/* table toolbar */}
              <PoliciesTableToolbar
                numSelected={selected.length}
                selected={selected}
                onClickSelectAll={selectAllPolicies}
                mutate={mutate}
                setSelected={setSelected}
              />
              {/* policies table */}
              <KwTable aria-label="Policies-Table">
                <KwTableHead>
                  <KwTableRow>
                    <KwTableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < visiblePolicies.length}
                        checked={visiblePolicies.length > 0 && selected.length === visiblePolicies.length}
                        onChange={selectAllPolicies}
                        inputProps={{
                          'aria-label': 'select all policies',
                        }}
                        indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
                      />
                    </KwTableCell>
                    {tableHeaders.map(({ title, onClick, sortValue }) => (
                      <KwColumnSortHeader
                        key={title}
                        title={title}
                        onClick={() => {
                          onClick();
                          setPage(0);
                        }}
                        sortValue={sortValue}
                      />
                    ))}
                  </KwTableRow>
                </KwTableHead>
                <KwTableBody>
                  {[...filteredPolicies]
                    .sort(getComparator(order, orderBy))
                    .slice(page * pageSize, (page + 1) * pageSize)
                    .map((policy, index) => {
                      const isItemSelected = isSelected(policy.id);
                      const labelId = `policy-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          role="row"
                          aria-checked={isItemSelected}
                          aria-label={`${policy.name} row`}
                          tabIndex={-1}
                          key={policy.id}
                          selected={isItemSelected}
                        >
                          <KwTableCell onClick={() => selectPolicy(policy.id)} tabIndex={-1} key={policy.id} selected={isItemSelected}>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': `${policy.name} checkbox`,
                                'aria-checked': isItemSelected,
                              }}
                              checkedIcon={<CheckBoxOutlinedIcon />}
                            />
                          </KwTableCell>
                          {/* POLICY NAME */}
                          <KwTableClickableCell onClick={() => navigate(`/policies/view/${policy.id}`)}>
                            <div>{policy.name}</div>
                            <Tooltip title={policy.description} arrow>
                              {policy.description.length > 45 ? (
                                <Typography variant="smallRegular">{policy.description.slice(0, 45)}...</Typography>
                              ) : (
                                <Typography variant="smallRegular">{policy.description}</Typography>
                              )}
                            </Tooltip>
                          </KwTableClickableCell>
                          {/* ASSOCIATED RULES */}
                          {policy.policyRules.length < 4 ? (
                            <KwTableCell>
                              <div>{policy.policyRules.map(policyRule => policyRule.rule.name).join(', ')}</div>
                            </KwTableCell>
                          ) : (
                            <>
                              <StyledKwTableCell>
                                <div>
                                  {policy.policyRules
                                    .map(policyRule => policyRule.rule.name)
                                    .slice(0, 3)
                                    .join(', ')}
                                  ,
                                </div>
                                <StyledLink
                                  onClick={event => handlePopoverClick(setRulesPolicyId, setRulesAnchorEl, event, policy.id)}
                                  underline="hover"
                                >
                                  +{policy.policyRules.length - 3} more
                                </StyledLink>
                              </StyledKwTableCell>
                              {/* associated rules popover */}
                              <PoliciesTableAssociatedRulesPopover
                                policy={policy}
                                anchorEl={rulesAnchorEl}
                                setAnchorEl={setRulesAnchorEl}
                                policyId={rulesPolicyId}
                                setPolicyId={setRulesPolicyId}
                                handleClose={() => handlePopoverClick(setRulesPolicyId, setRulesAnchorEl)}
                              />
                            </>
                          )}
                          {/* # OF DEVICES */}
                          <KwTableCell>
                            <div>{policy.numberOfDevices}</div>
                          </KwTableCell>
                          {/* POLICY CONFIGURATIONS */}
                          <StyledKwTableCell>
                            <StyledMailIcon
                              className={
                                policy.remediations.includes('MAIL') ||
                                policy.remediations.includes('NOTIFICATION') ||
                                policy.remediations.includes('PUSH_NOTIFICATION')
                                  ? 'checked'
                                  : ''
                              }
                            />
                            <StyledCloudOffIcon className={policy.remediations.includes('CLOUD') ? 'checked' : ''} />
                          </StyledKwTableCell>
                          {/* ENABLED */}
                          <KwTableCell>
                            <IconButton
                              size="medium"
                              onClick={() => updatePolicy(policy.id, policy.enabled, flags)}
                              aria-label={`enable-disable-policy-${index}-quick-toggle-button`}
                            >
                              {policy.enabled ? <ToggleOnOutlinedIcon color="primary" /> : <ToggleOffOutlinedIcon />}
                            </IconButton>
                          </KwTableCell>
                          {/* MORE */}
                          <KwTableCell>
                            <IconButton
                              size="medium"
                              onClick={event => handlePopoverClick(setMoreButtonPolicyId, setMoreButtonAnchorEl, event, policy.id)}
                            >
                              <StyledMoreVertIcon />
                            </IconButton>
                          </KwTableCell>
                          {/* more button popover */}
                          <PoliciesTableMorePopover
                            policy={policy}
                            anchorEl={moreButtonAnchorEl}
                            setAnchorEl={setMoreButtonAnchorEl}
                            policyId={moreButtonPolicyId}
                            setPolicyId={setMoreButtonPolicyId}
                            handleClose={() => handlePopoverClick(setMoreButtonPolicyId, setMoreButtonAnchorEl)}
                            mutate={mutate}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        </StyledTableRow>
                      );
                    })}
                </KwTableBody>
              </KwTable>
              {/* pagination */}
              {total > 10 ? (
                <KwTablePaginationWrapper>
                  <KwRowsPerPage
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pageSize={pageSize}
                    page={page}
                    totalNumberOfEntries={total}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <StyledPagination page={page} totalPageCount={totalPageCount} handleChangePage={handleChangePage} />
                </KwTablePaginationWrapper>
              ) : null}
            </StyledTableWrapper>
          </KwFilterWrapper>
        </KwContainer>
      </ThemeProvider>
    </>
  );
}

const StyledPolicyTableInfoWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 18px 0;
`;

const StyledPagination = styled(KwPaginationRouter)`
  display: grid;
  place-items: center;
`;

const StyledTableWrapper = styled('div')`
  width: 100%;
`;

const StyledTableRow = styled(KwTableRow)<{ selected?: boolean }>`
  ${KwTableBody} & {
    background-color: ${props => (props.selected ? 'rgba(79, 134, 142, 0.1)' : 'transparent')};
  }
`;

// configuration column
const configurationButtonStyles = css`
  color: rgb(158, 158, 158);
  font-size: 18px;
  margin: 0 20px 0 0;
  &.checked {
    color: rgb(88, 177, 100);
  }
`;

const StyledMailIcon = styled(MailIcon)`
  ${configurationButtonStyles}
`;

const StyledBackspaceIcon = styled(BackspaceIcon)`
  ${configurationButtonStyles}
`;

const StyledCloudOffIcon = styled(CloudOffIcon)`
  ${configurationButtonStyles}
`;

// more button column
const StyledMoreVertIcon = styled(MoreVertIcon)`
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  margin-left: 2px;
`;

const KwTableClickableCell = styled(KwTableCell)`
  cursor: pointer;
  max-width: 300px;
`;

const StyledKwTableCell = styled(KwTableCell)`
  max-width: 300px;
  min-width: 150px;
`;

const StyledKwButton = styled(KwButton)`
  min-width: 138px;
`;
