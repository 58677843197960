import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

export interface DataItem {
  key: string;
  value: number;
  color: string;
}

export interface AppDetailsPieChartProps {
  data: DataItem[];
}

interface CustomLabelProps {
  viewBox?: { cx: number; cy: number };
  value1: number;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ viewBox, value1 }) => {
  const { cx, cy } = viewBox!;
  return (
    <text x={cx} y={cy} fill="black" textAnchor="middle" dominantBaseline="middle">
      <tspan x={cx} dy="-0.05em" fontSize="46px" fontWeight="600">
        {value1}
      </tspan>
      <tspan x={cx} dy="2.25em" fontSize="14px" fontWeight="600">
        TOTAL
      </tspan>
    </text>
  );
};

const AppDetailsPieChart: React.FC<AppDetailsPieChartProps> = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ marginRight: '20px' }}>
        {data.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {item.value ? (
              <>
                <div
                  style={{
                    width: '18px',
                    height: '18px',
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    marginRight: '5px',
                  }}
                ></div>
                <span>{`${item.key} (${item.value})`}</span>
              </>
            ) : null}
          </div>
        ))}
      </div>
      <div>
        <PieChart width={250} height={250}>
          <Pie data={data} cx="50%" cy="50%" innerRadius={50} outerRadius={100} fill="#8884d8" paddingAngle={1} dataKey="value">
            {data.map((entry, index) => (
              // eslint-disable-next-line react/forbid-component-props
              <Cell key={`cell-${index}`} fill={entry.color} style={{ outline: 'none' }} />
            ))}
            <Label content={<CustomLabel value1={total} />} position="center" />
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default AppDetailsPieChart;
