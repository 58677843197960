import { styled } from '@mui/material/styles';
import { Chip, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useState } from 'react';
import { IssueDescriptionModal } from './IssueDescriptionModal';
import { IssueTypesModal } from './IssueTypesModal';

interface IProps {
  riskIndicators: { [title: string]: { isSecurityConcern: boolean; isPrivacyConcern: boolean; weight: number; description: string } };
}

export default function AppIssuesTable({ riskIndicators }: IProps) {
  const [selectedIssue, setSelectedIssue] = useState<{ title: string; description: string } | null>(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleOpenModal = (title: string, description: string) => {
    setSelectedIssue({ title, description });
  };

  const handleCloseModal = () => {
    setSelectedIssue(null);
  };

  const handleHelpIconClick = () => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalClose = () => {
    setIsHelpModalOpen(false);
  };

  return (
    <>
      <StyledTable aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="right">
              <HeaderContainer>
                Type
                <StyledHelpIcon onClick={handleHelpIconClick} />
              </HeaderContainer>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(riskIndicators).map(([title, { isSecurityConcern, isPrivacyConcern, weight, description }]) => {
            let warningIconColor: 'inherit' | 'error' | 'warning' = 'inherit';
            if (weight >= 75) {
              warningIconColor = 'error';
            } else if (weight >= 35) {
              warningIconColor = 'warning';
            }
            return (
              <StyledTableRow key={title} onClick={() => handleOpenModal(title, description)}>
                <TableCell>
                  <StyledWarningIcon color={warningIconColor} />
                  {title}
                </TableCell>
                <TableCell align="right">
                  <StyledChip
                    label={isSecurityConcern && isPrivacyConcern ? 'Security & Privacy' : isSecurityConcern ? 'Security' : 'Privacy'}
                  />
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
        {selectedIssue && (
          <IssueDescriptionModal
            title={selectedIssue.title}
            description={selectedIssue.description}
            isOpen={Boolean(selectedIssue)}
            onClose={handleCloseModal}
          />
        )}
      </StyledTable>
      <IssueTypesModal isOpen={isHelpModalOpen} onClose={handleHelpModalClose} />
    </>
  );
}

const StyledTable = styled(Table)({
  backgroundColor: 'white',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    color: theme.palette.common.black,
    borderBottom: 'none',
    paddingRight: 32,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledChip = styled(Chip)`
  background-color: #dce7e8;
  color: #244c5a;
  height: 36px;
  margin-right: 10px;
  font-weight: 800;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: rgba(79, 134, 142, 0.1);
  }
`;

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.base.grey,
  '&.MuiSvgIcon-colorError': {
    color: theme.palette.error.main,
  },
  '&.MuiSvgIcon-colorWarning': {
    color: theme.palette.warning.main,
  },
  display: 'inline-block',
  margin: '5px 5px -6px 0',
}));

const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const HeaderContainer = styled('div')({
  display: 'inline-flex',
  gap: '4px',
});
