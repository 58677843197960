export const countriesMap = {
  AD: {
    countryCode: 'AD',
    countryName: 'Andorra',
    latitude: 42.546245,
    longitude: 1.601554,
  },
  AE: {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    latitude: 23.424076,
    longitude: 53.847818,
  },
  AF: {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    latitude: 33.93911,
    longitude: 67.709953,
  },
  AG: {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    latitude: 17.060816,
    longitude: -61.796428,
  },
  AI: {
    countryCode: 'AI',
    countryName: 'Anguilla',
    latitude: 18.220554,
    longitude: -63.068615,
  },
  AL: {
    countryCode: 'AL',
    countryName: 'Albania',
    latitude: 41.153332,
    longitude: 20.168331,
  },
  AM: {
    countryCode: 'AM',
    countryName: 'Armenia',
    latitude: 40.069099,
    longitude: 45.038189,
  },
  AN: {
    countryCode: 'AN',
    countryName: 'Netherlands Antilles',
    latitude: 12.226079,
    longitude: -69.060087,
  },
  AO: {
    countryCode: 'AO',
    countryName: 'Angola',
    latitude: -11.202692,
    longitude: 17.873887,
  },
  AQ: {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    latitude: -75.250973,
    longitude: -0.071389,
  },
  AR: {
    countryCode: 'AR',
    countryName: 'Argentina',
    latitude: -38.416097,
    longitude: -63.616672,
  },
  AS: {
    countryCode: 'AS',
    countryName: 'American Samoa',
    latitude: -14.270972,
    longitude: -170.132217,
  },
  AT: {
    countryCode: 'AT',
    countryName: 'Austria',
    latitude: 47.516231,
    longitude: 14.550072,
  },
  AU: {
    countryCode: 'AU',
    countryName: 'Australia',
    latitude: -25.274398,
    longitude: 133.775136,
  },
  AW: {
    countryCode: 'AW',
    countryName: 'Aruba',
    latitude: 12.52111,
    longitude: -69.968338,
  },
  AZ: {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    latitude: 40.143105,
    longitude: 47.576927,
  },
  BA: {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    latitude: 43.915886,
    longitude: 17.679076,
  },
  BB: {
    countryCode: 'BB',
    countryName: 'Barbados',
    latitude: 13.193887,
    longitude: -59.543198,
  },
  BD: {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    latitude: 23.684994,
    longitude: 90.356331,
  },
  BE: {
    countryCode: 'BE',
    countryName: 'Belgium',
    latitude: 50.503887,
    longitude: 4.469936,
  },
  BF: {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    latitude: 12.238333,
    longitude: -1.561593,
  },
  BG: {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    latitude: 42.733883,
    longitude: 25.48583,
  },
  BH: {
    countryCode: 'BH',
    countryName: 'Bahrain',
    latitude: 25.930414,
    longitude: 50.637772,
  },
  BI: {
    countryCode: 'BI',
    countryName: 'Burundi',
    latitude: -3.373056,
    longitude: 29.918886,
  },
  BJ: {
    countryCode: 'BJ',
    countryName: 'Benin',
    latitude: 9.30769,
    longitude: 2.315834,
  },
  BM: {
    countryCode: 'BM',
    countryName: 'Bermuda',
    latitude: 32.321384,
    longitude: -64.75737,
  },
  BN: {
    countryCode: 'BN',
    countryName: 'Brunei',
    latitude: 4.535277,
    longitude: 114.727669,
  },
  BO: {
    countryCode: 'BO',
    countryName: 'Bolivia',
    latitude: -16.290154,
    longitude: -63.588653,
  },
  BR: {
    countryCode: 'BR',
    countryName: 'Brazil',
    latitude: -14.235004,
    longitude: -51.92528,
  },
  BS: {
    countryCode: 'BS',
    countryName: 'Bahamas',
    latitude: 25.03428,
    longitude: -77.39628,
  },
  BT: {
    countryCode: 'BT',
    countryName: 'Bhutan',
    latitude: 27.514162,
    longitude: 90.433601,
  },
  BV: {
    countryCode: 'BV',
    countryName: 'Bouvet Island',
    latitude: -54.423199,
    longitude: 3.413194,
  },
  BW: {
    countryCode: 'BW',
    countryName: 'Botswana',
    latitude: -22.328474,
    longitude: 24.684866,
  },
  BY: {
    countryCode: 'BY',
    countryName: 'Belarus',
    latitude: 53.709807,
    longitude: 27.953389,
  },
  BZ: {
    countryCode: 'BZ',
    countryName: 'Belize',
    latitude: 17.189877,
    longitude: -88.49765,
  },
  CA: {
    countryCode: 'CA',
    countryName: 'Canada',
    latitude: 56.130366,
    longitude: -106.346771,
  },
  CC: {
    countryCode: 'CC',
    countryName: 'Cocos [Keeling] Islands',
    latitude: -12.164165,
    longitude: 96.870956,
  },
  CD: {
    countryCode: 'CD',
    countryName: 'Congo [DRC]',
    latitude: -4.038333,
    longitude: 21.758664,
  },
  CF: {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    latitude: 6.611111,
    longitude: 20.939444,
  },
  CG: {
    countryCode: 'CG',
    countryName: 'Congo [Republic]',
    latitude: -0.228021,
    longitude: 15.827659,
  },
  CH: {
    countryCode: 'CH',
    countryName: 'Switzerland',
    latitude: 46.818188,
    longitude: 8.227512,
  },
  CI: {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    latitude: 7.539989,
    longitude: -5.54708,
  },
  CK: {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    latitude: -21.236736,
    longitude: -159.777671,
  },
  CL: {
    countryCode: 'CL',
    countryName: 'Chile',
    latitude: -35.675147,
    longitude: -71.542969,
  },
  CM: {
    countryCode: 'CM',
    countryName: 'Cameroon',
    latitude: 7.369722,
    longitude: 12.354722,
  },
  CN: {
    countryCode: 'CN',
    countryName: 'China',
    latitude: 35.86166,
    longitude: 104.195397,
  },
  CO: {
    countryCode: 'CO',
    countryName: 'Colombia',
    latitude: 4.570868,
    longitude: -74.297333,
  },
  CR: {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    latitude: 9.748917,
    longitude: -83.753428,
  },
  CU: {
    countryCode: 'CU',
    countryName: 'Cuba',
    latitude: 21.521757,
    longitude: -77.781167,
  },
  CV: {
    countryCode: 'CV',
    countryName: 'Cape Verde',
    latitude: 16.002082,
    longitude: -24.013197,
  },
  CX: {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    latitude: -10.447525,
    longitude: 105.690449,
  },
  CY: {
    countryCode: 'CY',
    countryName: 'Cyprus',
    latitude: 35.126413,
    longitude: 33.429859,
  },
  CZ: {
    countryCode: 'CZ',
    countryName: 'Czech Republic',
    latitude: 49.817492,
    longitude: 15.472962,
  },
  DE: {
    countryCode: 'DE',
    countryName: 'Germany',
    latitude: 51.165691,
    longitude: 10.451526,
  },
  DJ: {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    latitude: 11.825138,
    longitude: 42.590275,
  },
  DK: {
    countryCode: 'DK',
    countryName: 'Denmark',
    latitude: 56.26392,
    longitude: 9.501785,
  },
  DM: {
    countryCode: 'DM',
    countryName: 'Dominica',
    latitude: 15.414999,
    longitude: -61.370976,
  },
  DO: {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    latitude: 18.735693,
    longitude: -70.162651,
  },
  DZ: {
    countryCode: 'DZ',
    countryName: 'Algeria',
    latitude: 28.033886,
    longitude: 1.659626,
  },
  EC: {
    countryCode: 'EC',
    countryName: 'Ecuador',
    latitude: -1.831239,
    longitude: -78.183406,
  },
  EE: {
    countryCode: 'EE',
    countryName: 'Estonia',
    latitude: 58.595272,
    longitude: 25.013607,
  },
  EG: {
    countryCode: 'EG',
    countryName: 'Egypt',
    latitude: 26.820553,
    longitude: 30.802498,
  },
  EH: {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    latitude: 24.215527,
    longitude: -12.885834,
  },
  ER: {
    countryCode: 'ER',
    countryName: 'Eritrea',
    latitude: 15.179384,
    longitude: 39.782334,
  },
  ES: {
    countryCode: 'ES',
    countryName: 'Spain',
    latitude: 40.463667,
    longitude: -3.74922,
  },
  ET: {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    latitude: 9.145,
    longitude: 40.489673,
  },
  FI: {
    countryCode: 'FI',
    countryName: 'Finland',
    latitude: 61.92411,
    longitude: 25.748151,
  },
  FJ: {
    countryCode: 'FJ',
    countryName: 'Fiji',
    latitude: -16.578193,
    longitude: 179.414413,
  },
  FK: {
    countryCode: 'FK',
    countryName: 'Falkland Islands [Islas Malvinas]',
    latitude: -51.796253,
    longitude: -59.523613,
  },
  FM: {
    countryCode: 'FM',
    countryName: 'Micronesia',
    latitude: 7.425554,
    longitude: 150.550812,
  },
  FO: {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    latitude: 61.892635,
    longitude: -6.911806,
  },
  FR: {
    countryCode: 'FR',
    countryName: 'France',
    latitude: 46.227638,
    longitude: 2.213749,
  },
  GA: {
    countryCode: 'GA',
    countryName: 'Gabon',
    latitude: -0.803689,
    longitude: 11.609444,
  },
  GB: {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    latitude: 55.378051,
    longitude: -3.435973,
  },
  GD: {
    countryCode: 'GD',
    countryName: 'Grenada',
    latitude: 12.262776,
    longitude: -61.604171,
  },
  GE: {
    countryCode: 'GE',
    countryName: 'Georgia',
    latitude: 42.315407,
    longitude: 43.356892,
  },
  GF: {
    countryCode: 'GF',
    countryName: 'French Guiana',
    latitude: 3.933889,
    longitude: -53.125782,
  },
  GG: {
    countryCode: 'GG',
    countryName: 'Guernsey',
    latitude: 49.465691,
    longitude: -2.585278,
  },
  GH: {
    countryCode: 'GH',
    countryName: 'Ghana',
    latitude: 7.946527,
    longitude: -1.023194,
  },
  GI: {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    latitude: 36.137741,
    longitude: -5.345374,
  },
  GL: {
    countryCode: 'GL',
    countryName: 'Greenland',
    latitude: 71.706936,
    longitude: -42.604303,
  },
  GM: {
    countryCode: 'GM',
    countryName: 'Gambia',
    latitude: 13.443182,
    longitude: -15.310139,
  },
  GN: {
    countryCode: 'GN',
    countryName: 'Guinea',
    latitude: 9.945587,
    longitude: -9.696645,
  },
  GP: {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    latitude: 16.995971,
    longitude: -62.067641,
  },
  GQ: {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    latitude: 1.650801,
    longitude: 10.267895,
  },
  GR: {
    countryCode: 'GR',
    countryName: 'Greece',
    latitude: 39.074208,
    longitude: 21.824312,
  },
  GS: {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    latitude: -54.429579,
    longitude: -36.587909,
  },
  GT: {
    countryCode: 'GT',
    countryName: 'Guatemala',
    latitude: 15.783471,
    longitude: -90.230759,
  },
  GU: {
    countryCode: 'GU',
    countryName: 'Guam',
    latitude: 13.444304,
    longitude: 144.793731,
  },
  GW: {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    latitude: 11.803749,
    longitude: -15.180413,
  },
  GY: {
    countryCode: 'GY',
    countryName: 'Guyana',
    latitude: 4.860416,
    longitude: -58.93018,
  },
  GZ: {
    countryCode: 'GZ',
    countryName: 'Gaza Strip',
    latitude: 31.354676,
    longitude: 34.308825,
  },
  HK: {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    latitude: 22.396428,
    longitude: 114.109497,
  },
  HM: {
    countryCode: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    latitude: -53.08181,
    longitude: 73.504158,
  },
  HN: {
    countryCode: 'HN',
    countryName: 'Honduras',
    latitude: 15.199999,
    longitude: -86.241905,
  },
  HR: {
    countryCode: 'HR',
    countryName: 'Croatia',
    latitude: 45.1,
    longitude: 15.2,
  },
  HT: {
    countryCode: 'HT',
    countryName: 'Haiti',
    latitude: 18.971187,
    longitude: -72.285215,
  },
  HU: {
    countryCode: 'HU',
    countryName: 'Hungary',
    latitude: 47.162494,
    longitude: 19.503304,
  },
  ID: {
    countryCode: 'ID',
    countryName: 'Indonesia',
    latitude: -0.789275,
    longitude: 113.921327,
  },
  IE: {
    countryCode: 'IE',
    countryName: 'Ireland',
    latitude: 53.41291,
    longitude: -8.24389,
  },
  IL: {
    countryCode: 'IL',
    countryName: 'Israel',
    latitude: 31.046051,
    longitude: 34.851612,
  },
  IM: {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    latitude: 54.236107,
    longitude: -4.548056,
  },
  IN: {
    countryCode: 'IN',
    countryName: 'India',
    latitude: 20.593684,
    longitude: 78.96288,
  },
  IO: {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    latitude: -6.343194,
    longitude: 71.876519,
  },
  IQ: {
    countryCode: 'IQ',
    countryName: 'Iraq',
    latitude: 33.223191,
    longitude: 43.679291,
  },
  IR: {
    countryCode: 'IR',
    countryName: 'Iran',
    latitude: 32.427908,
    longitude: 53.688046,
  },
  IS: {
    countryCode: 'IS',
    countryName: 'Iceland',
    latitude: 64.963051,
    longitude: -19.020835,
  },
  IT: {
    countryCode: 'IT',
    countryName: 'Italy',
    latitude: 41.87194,
    longitude: 12.56738,
  },
  JE: {
    countryCode: 'JE',
    countryName: 'Jersey',
    latitude: 49.214439,
    longitude: -2.13125,
  },
  JM: {
    countryCode: 'JM',
    countryName: 'Jamaica',
    latitude: 18.109581,
    longitude: -77.297508,
  },
  JO: {
    countryCode: 'JO',
    countryName: 'Jordan',
    latitude: 30.585164,
    longitude: 36.238414,
  },
  JP: {
    countryCode: 'JP',
    countryName: 'Japan',
    latitude: 36.204824,
    longitude: 138.252924,
  },
  KE: {
    countryCode: 'KE',
    countryName: 'Kenya',
    latitude: -0.023559,
    longitude: 37.906193,
  },
  KG: {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    latitude: 41.20438,
    longitude: 74.766098,
  },
  KH: {
    countryCode: 'KH',
    countryName: 'Cambodia',
    latitude: 12.565679,
    longitude: 104.990963,
  },
  KI: {
    countryCode: 'KI',
    countryName: 'Kiribati',
    latitude: -3.370417,
    longitude: -168.734039,
  },
  KM: {
    countryCode: 'KM',
    countryName: 'Comoros',
    latitude: -11.875001,
    longitude: 43.872219,
  },
  KN: {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    latitude: 17.357822,
    longitude: -62.782998,
  },
  KP: {
    countryCode: 'KP',
    countryName: 'North Korea',
    latitude: 40.339852,
    longitude: 127.510093,
  },
  KR: {
    countryCode: 'KR',
    countryName: 'South Korea',
    latitude: 35.907757,
    longitude: 127.766922,
  },
  KW: {
    countryCode: 'KW',
    countryName: 'Kuwait',
    latitude: 29.31166,
    longitude: 47.481766,
  },
  KY: {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    latitude: 19.513469,
    longitude: -80.566956,
  },
  KZ: {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    latitude: 48.019573,
    longitude: 66.923684,
  },
  LA: {
    countryCode: 'LA',
    countryName: 'Laos',
    latitude: 19.85627,
    longitude: 102.495496,
  },
  LB: {
    countryCode: 'LB',
    countryName: 'Lebanon',
    latitude: 33.854721,
    longitude: 35.862285,
  },
  LC: {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    latitude: 13.909444,
    longitude: -60.978893,
  },
  LI: {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    latitude: 47.166,
    longitude: 9.555373,
  },
  LK: {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    latitude: 7.873054,
    longitude: 80.771797,
  },
  LR: {
    countryCode: 'LR',
    countryName: 'Liberia',
    latitude: 6.428055,
    longitude: -9.429499,
  },
  LS: {
    countryCode: 'LS',
    countryName: 'Lesotho',
    latitude: -29.609988,
    longitude: 28.233608,
  },
  LT: {
    countryCode: 'LT',
    countryName: 'Lithuania',
    latitude: 55.169438,
    longitude: 23.881275,
  },
  LU: {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    latitude: 49.815273,
    longitude: 6.129583,
  },
  LV: {
    countryCode: 'LV',
    countryName: 'Latvia',
    latitude: 56.879635,
    longitude: 24.603189,
  },
  LY: {
    countryCode: 'LY',
    countryName: 'Libya',
    latitude: 26.3351,
    longitude: 17.228331,
  },
  MA: {
    countryCode: 'MA',
    countryName: 'Morocco',
    latitude: 31.791702,
    longitude: -7.09262,
  },
  MC: {
    countryCode: 'MC',
    countryName: 'Monaco',
    latitude: 43.750298,
    longitude: 7.412841,
  },
  MD: {
    countryCode: 'MD',
    countryName: 'Moldova',
    latitude: 47.411631,
    longitude: 28.369885,
  },
  ME: {
    countryCode: 'ME',
    countryName: 'Montenegro',
    latitude: 42.708678,
    longitude: 19.37439,
  },
  MG: {
    countryCode: 'MG',
    countryName: 'Madagascar',
    latitude: -18.766947,
    longitude: 46.869107,
  },
  MH: {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    latitude: 7.131474,
    longitude: 171.184478,
  },
  MK: {
    countryCode: 'MK',
    countryName: 'Macedonia [FYROM]',
    latitude: 41.608635,
    longitude: 21.745275,
  },
  ML: {
    countryCode: 'ML',
    countryName: 'Mali',
    latitude: 17.570692,
    longitude: -3.996166,
  },
  MM: {
    countryCode: 'MM',
    countryName: 'Myanmar [Burma]',
    latitude: 21.913965,
    longitude: 95.956223,
  },
  MN: {
    countryCode: 'MN',
    countryName: 'Mongolia',
    latitude: 46.862496,
    longitude: 103.846656,
  },
  MO: {
    countryCode: 'MO',
    countryName: 'Macau',
    latitude: 22.198745,
    longitude: 113.543873,
  },
  MP: {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    latitude: 17.33083,
    longitude: 145.38469,
  },
  MQ: {
    countryCode: 'MQ',
    countryName: 'Martinique',
    latitude: 14.641528,
    longitude: -61.024174,
  },
  MR: {
    countryCode: 'MR',
    countryName: 'Mauritania',
    latitude: 21.00789,
    longitude: -10.940835,
  },
  MS: {
    countryCode: 'MS',
    countryName: 'Montserrat',
    latitude: 16.742498,
    longitude: -62.187366,
  },
  MT: {
    countryCode: 'MT',
    countryName: 'Malta',
    latitude: 35.937496,
    longitude: 14.375416,
  },
  MU: {
    countryCode: 'MU',
    countryName: 'Mauritius',
    latitude: -20.348404,
    longitude: 57.552152,
  },
  MV: {
    countryCode: 'MV',
    countryName: 'Maldives',
    latitude: 3.202778,
    longitude: 73.22068,
  },
  MW: {
    countryCode: 'MW',
    countryName: 'Malawi',
    latitude: -13.254308,
    longitude: 34.301525,
  },
  MX: {
    countryCode: 'MX',
    countryName: 'Mexico',
    latitude: 23.634501,
    longitude: -102.552784,
  },
  MY: {
    countryCode: 'MY',
    countryName: 'Malaysia',
    latitude: 4.210484,
    longitude: 101.975766,
  },
  MZ: {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    latitude: -18.665695,
    longitude: 35.529562,
  },
  NA: {
    countryCode: 'NA',
    countryName: 'Namibia',
    latitude: -22.95764,
    longitude: 18.49041,
  },
  NC: {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    latitude: -20.904305,
    longitude: 165.618042,
  },
  NE: {
    countryCode: 'NE',
    countryName: 'Niger',
    latitude: 17.607789,
    longitude: 8.081666,
  },
  NF: {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    latitude: -29.040835,
    longitude: 167.954712,
  },
  NG: {
    countryCode: 'NG',
    countryName: 'Nigeria',
    latitude: 9.081999,
    longitude: 8.675277,
  },
  NI: {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    latitude: 12.865416,
    longitude: -85.207229,
  },
  NL: {
    countryCode: 'NL',
    countryName: 'Netherlands',
    latitude: 52.132633,
    longitude: 5.291266,
  },
  NO: {
    countryCode: 'NO',
    countryName: 'Norway',
    latitude: 60.472024,
    longitude: 8.468946,
  },
  NP: {
    countryCode: 'NP',
    countryName: 'Nepal',
    latitude: 28.394857,
    longitude: 84.124008,
  },
  NR: {
    countryCode: 'NR',
    countryName: 'Nauru',
    latitude: -0.522778,
    longitude: 166.931503,
  },
  NU: {
    countryCode: 'NU',
    countryName: 'Niue',
    latitude: -19.054445,
    longitude: -169.867233,
  },
  NZ: {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    latitude: -40.900557,
    longitude: 174.885971,
  },
  OM: {
    countryCode: 'OM',
    countryName: 'Oman',
    latitude: 21.512583,
    longitude: 55.923255,
  },
  PA: {
    countryCode: 'PA',
    countryName: 'Panama',
    latitude: 8.537981,
    longitude: -80.782127,
  },
  PE: {
    countryCode: 'PE',
    countryName: 'Peru',
    latitude: -9.189967,
    longitude: -75.015152,
  },
  PF: {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    latitude: -17.679742,
    longitude: -149.406843,
  },
  PG: {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    latitude: -6.314993,
    longitude: 143.95555,
  },
  PH: {
    countryCode: 'PH',
    countryName: 'Philippines',
    latitude: 12.879721,
    longitude: 121.774017,
  },
  PK: {
    countryCode: 'PK',
    countryName: 'Pakistan',
    latitude: 30.375321,
    longitude: 69.345116,
  },
  PL: {
    countryCode: 'PL',
    countryName: 'Poland',
    latitude: 51.919438,
    longitude: 19.145136,
  },
  PM: {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    latitude: 46.941936,
    longitude: -56.27111,
  },
  PN: {
    countryCode: 'PN',
    countryName: 'Pitcairn Islands',
    latitude: -24.703615,
    longitude: -127.439308,
  },
  PR: {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    latitude: 18.220833,
    longitude: -66.590149,
  },
  PS: {
    countryCode: 'PS',
    countryName: 'Palestinian Territories',
    latitude: 31.952162,
    longitude: 35.233154,
  },
  PT: {
    countryCode: 'PT',
    countryName: 'Portugal',
    latitude: 39.399872,
    longitude: -8.224454,
  },
  PW: {
    countryCode: 'PW',
    countryName: 'Palau',
    latitude: 7.51498,
    longitude: 134.58252,
  },
  PY: {
    countryCode: 'PY',
    countryName: 'Paraguay',
    latitude: -23.442503,
    longitude: -58.443832,
  },
  QA: {
    countryCode: 'QA',
    countryName: 'Qatar',
    latitude: 25.354826,
    longitude: 51.183884,
  },
  RE: {
    countryCode: 'RE',
    countryName: 'Réunion',
    latitude: -21.115141,
    longitude: 55.536384,
  },
  RO: {
    countryCode: 'RO',
    countryName: 'Romania',
    latitude: 45.943161,
    longitude: 24.96676,
  },
  RS: {
    countryCode: 'RS',
    countryName: 'Serbia',
    latitude: 44.016521,
    longitude: 21.005859,
  },
  RU: {
    countryCode: 'RU',
    countryName: 'Russia',
    latitude: 61.52401,
    longitude: 105.318756,
  },
  RW: {
    countryCode: 'RW',
    countryName: 'Rwanda',
    latitude: -1.940278,
    longitude: 29.873888,
  },
  SA: {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    latitude: 23.885942,
    longitude: 45.079162,
  },
  SB: {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    latitude: -9.64571,
    longitude: 160.156194,
  },
  SC: {
    countryCode: 'SC',
    countryName: 'Seychelles',
    latitude: -4.679574,
    longitude: 55.491977,
  },
  SD: {
    countryCode: 'SD',
    countryName: 'Sudan',
    latitude: 12.862807,
    longitude: 30.217636,
  },
  SE: {
    countryCode: 'SE',
    countryName: 'Sweden',
    latitude: 60.128161,
    longitude: 18.643501,
  },
  SG: {
    countryCode: 'SG',
    countryName: 'Singapore',
    latitude: 1.352083,
    longitude: 103.819836,
  },
  SH: {
    countryCode: 'SH',
    countryName: 'Saint Helena',
    latitude: -24.143474,
    longitude: -10.030696,
  },
  SI: {
    countryCode: 'SI',
    countryName: 'Slovenia',
    latitude: 46.151241,
    longitude: 14.995463,
  },
  SJ: {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    latitude: 77.553604,
    longitude: 23.670272,
  },
  SK: {
    countryCode: 'SK',
    countryName: 'Slovakia',
    latitude: 48.669026,
    longitude: 19.699024,
  },
  SL: {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    latitude: 8.460555,
    longitude: -11.779889,
  },
  SM: {
    countryCode: 'SM',
    countryName: 'San Marino',
    latitude: 43.94236,
    longitude: 12.457777,
  },
  SN: {
    countryCode: 'SN',
    countryName: 'Senegal',
    latitude: 14.497401,
    longitude: -14.452362,
  },
  SO: {
    countryCode: 'SO',
    countryName: 'Somalia',
    latitude: 5.152149,
    longitude: 46.199616,
  },
  SR: {
    countryCode: 'SR',
    countryName: 'Suriname',
    latitude: 3.919305,
    longitude: -56.027783,
  },
  ST: {
    countryCode: 'ST',
    countryName: 'São Tomé and Príncipe',
    latitude: 0.18636,
    longitude: 6.613081,
  },
  SV: {
    countryCode: 'SV',
    countryName: 'El Salvador',
    latitude: 13.794185,
    longitude: -88.89653,
  },
  SY: {
    countryCode: 'SY',
    countryName: 'Syria',
    latitude: 34.802075,
    longitude: 38.996815,
  },
  SZ: {
    countryCode: 'SZ',
    countryName: 'Swaziland',
    latitude: -26.522503,
    longitude: 31.465866,
  },
  TC: {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    latitude: 21.694025,
    longitude: -71.797928,
  },
  TD: {
    countryCode: 'TD',
    countryName: 'Chad',
    latitude: 15.454166,
    longitude: 18.732207,
  },
  TF: {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    latitude: -49.280366,
    longitude: 69.348557,
  },
  TG: {
    countryCode: 'TG',
    countryName: 'Togo',
    latitude: 8.619543,
    longitude: 0.824782,
  },
  TH: {
    countryCode: 'TH',
    countryName: 'Thailand',
    latitude: 15.870032,
    longitude: 100.992541,
  },
  TJ: {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    latitude: 38.861034,
    longitude: 71.276093,
  },
  TK: {
    countryCode: 'TK',
    countryName: 'Tokelau',
    latitude: -8.967363,
    longitude: -171.855881,
  },
  TL: {
    countryCode: 'TL',
    countryName: 'Timor-Leste',
    latitude: -8.874217,
    longitude: 125.727539,
  },
  TM: {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    latitude: 38.969719,
    longitude: 59.556278,
  },
  TN: {
    countryCode: 'TN',
    countryName: 'Tunisia',
    latitude: 33.886917,
    longitude: 9.537499,
  },
  TO: {
    countryCode: 'TO',
    countryName: 'Tonga',
    latitude: -21.178986,
    longitude: -175.198242,
  },
  TR: {
    countryCode: 'TR',
    countryName: 'Turkey',
    latitude: 38.963745,
    longitude: 35.243322,
  },
  TT: {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    latitude: 10.691803,
    longitude: -61.222503,
  },
  TV: {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    latitude: -7.109535,
    longitude: 177.64933,
  },
  TW: {
    countryCode: 'TW',
    countryName: 'Taiwan',
    latitude: 23.69781,
    longitude: 120.960515,
  },
  TZ: {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    latitude: -6.369028,
    longitude: 34.888822,
  },
  UA: {
    countryCode: 'UA',
    countryName: 'Ukraine',
    latitude: 48.379433,
    longitude: 31.16558,
  },
  UG: {
    countryCode: 'UG',
    countryName: 'Uganda',
    latitude: 1.373333,
    longitude: 32.290275,
  },
  UM: {
    countryCode: 'UM',
    countryName: 'U.S. Minor Outlying Islands',
    latitude: 19.2954,
    longitude: 166.628,
  },
  US: {
    countryCode: 'US',
    countryName: 'United States',
    latitude: 37.09024,
    longitude: -95.712891,
  },
  UY: {
    countryCode: 'UY',
    countryName: 'Uruguay',
    latitude: -32.522779,
    longitude: -55.765835,
  },
  UZ: {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    latitude: 41.377491,
    longitude: 64.585262,
  },
  VA: {
    countryCode: 'VA',
    countryName: 'Vatican City',
    latitude: 41.902916,
    longitude: 12.453389,
  },
  VC: {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    latitude: 12.984305,
    longitude: -61.287228,
  },
  VE: {
    countryCode: 'VE',
    countryName: 'Venezuela',
    latitude: 6.42375,
    longitude: -66.58973,
  },
  VG: {
    countryCode: 'VG',
    countryName: 'British Virgin Islands',
    latitude: 18.420695,
    longitude: -64.639968,
  },
  VI: {
    countryCode: 'VI',
    countryName: 'U.S. Virgin Islands',
    latitude: 18.335765,
    longitude: -64.896335,
  },
  VN: {
    countryCode: 'VN',
    countryName: 'Vietnam',
    latitude: 14.058324,
    longitude: 108.277199,
  },
  VU: {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    latitude: -15.376706,
    longitude: 166.959158,
  },
  WF: {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    latitude: -13.768752,
    longitude: -177.156097,
  },
  WS: {
    countryCode: 'WS',
    countryName: 'Samoa',
    latitude: -13.759029,
    longitude: -172.104629,
  },
  XK: {
    countryCode: 'XK',
    countryName: 'Kosovo',
    latitude: 42.602636,
    longitude: 20.902977,
  },
  YE: {
    countryCode: 'YE',
    countryName: 'Yemen',
    latitude: 15.552727,
    longitude: 48.516388,
  },
  YT: {
    countryCode: 'YT',
    countryName: 'Mayotte',
    latitude: -12.8275,
    longitude: 45.166244,
  },
  ZA: {
    countryCode: 'ZA',
    countryName: 'South Africa',
    latitude: -30.559482,
    longitude: 22.937506,
  },
  ZM: {
    countryCode: 'ZM',
    countryName: 'Zambia',
    latitude: -13.133897,
    longitude: 27.849332,
  },
  ZW: {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    latitude: -19.015438,
    longitude: 29.154857,
  },
};
