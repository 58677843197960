import useSWR, { KeyedMutator } from 'swr';
import { constructRequestError } from './RequestError';

export const urlMap = (path: string) => {
  if (path.startsWith('/device/') || path.startsWith('/application/')) {
    return process.env.DEVICE_SERVICE_URL;
  }
  if (path.startsWith('/rule/')) {
    return process.env.RULES_SERVICE_URL;
  }
  if (path.startsWith('/organizations/')) {
    return process.env.ORGANIZATION_SERVICE_URL;
  }
  if (path.startsWith('/pdm/')) {
    return process.env.PDM_SERVICE_URL;
  }
  if (path.startsWith('/risk-score/')) {
    return process.env.RISK_SCORE_SERVICE_URL;
  }
  if (path.startsWith('/eval/')) {
    return process.env.EVAL_SERVICE_URL;
  }
  if (path.startsWith('/list/')) {
    return process.env.LIST_SERVICE_URL;
  }
  throw Error(`unknown url prefix for url=${path}`);
};

export const fetchService = async (path: string, accessToken) => {
  const res = await fetch(`${urlMap(path)}${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!res.ok) {
    throw await constructRequestError(res);
  }

  return res.json();
};

// FIXME the return type should not use any here - but so many users of this
// don't specify their types, too many to fix in this PR.
export const useFetchService = <RT>(
  path: string,
  accessToken: string,
  shouldFetch = true,
): { data: RT | any; error: boolean; mutate: KeyedMutator<any> } => {
  const key = shouldFetch ? [path, accessToken] : null;
  const fetcher = () => fetchService(path, accessToken);
  const options = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
  const { data, error, mutate } = useSWR(key, fetcher, options);
  return { data, error, mutate };
};
