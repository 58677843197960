export interface PolicyAction {
  id: number;
  name: string;
  description: string;
  key: string;
  shouldShowModal?: boolean;
  emails?: string[];
}

export interface PolicyActionGroup {
  group: 'messaging' | 'suspension';
  groupName: string;
  actions: PolicyAction[];
}

export function getMdmActions(): PolicyActionGroup[] {
  return [
    {
      group: 'messaging',
      groupName: 'Notify Admin',
      actions: [
        {
          id: 1,
          name: 'Email alert',
          description: '',
          key: 'NOTIFICATION',
          shouldShowModal: true,
        },
      ],
    },
  ];
}

export function getActions(): PolicyActionGroup[] {
  return [
    {
      group: 'messaging',
      groupName: 'Notify Device Owner',
      actions: [
        {
          id: 1,
          name: 'Send email',
          description: '',
          key: 'MAIL',
        },
        {
          id: 2,
          name: 'Send push notification',
          description: '',
          key: 'PUSH_NOTIFICATION',
        },
      ],
    },
    {
      group: 'suspension',
      groupName: 'Conditional Access',
      actions: [
        {
          id: 3,
          name: 'Suspend Device in Okta Verify',
          description: '',
          key: 'CLOUD',
        },
      ],
    },
  ];
}
