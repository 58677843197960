import { CardContent, styled } from '@mui/material';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { PageLoader } from '../../PageLoader';
import { countriesMap } from '../../utils/countriesMap';
import { MapScrollWheelZoomNotifier, scrollInstructions } from '../shared/MapScrollWheelZoomNotifier';
import './css/map.css';
import '../shared/css/mapScrollWheelZoomNotifier.css';

const maxMarkerSize = 60;

interface Country {
  countryCode: string;
  count: number;
  countryName: string;
  latitude: number;
  longitude: number;
}
export function ApplicationsCountriesContactedMap({
  data = {},
  error,
  highRiskCountries,
  highRiskCountriesError,
  isLoading,
}: {
  data: { [key: string]: number };
  error: boolean;
  highRiskCountries: string[];
  highRiskCountriesError: boolean;
  isLoading: boolean;
}) {
  const countryMapData: { [key: string]: Country } = {};
  Object.entries(data).map(([countryCode, count]) => {
    const country = {
      countryCode,
      count: count as number,
      countryName: countriesMap[countryCode].countryName,
      latitude: countriesMap[countryCode].latitude,
      longitude: countriesMap[countryCode].longitude,
    };
    countryMapData[countryCode] = country;
    // eslint-disable-next-line consistent-return
    return country;
  });
  const filteredCountries = Object.values(countryMapData);
  const appsMaxCount = Math.max(...(filteredCountries as { count: number }[]).map(country => country.count));

  const MarkerWithToolTip = ({
    marker,
    className,
  }: {
    marker: {
      latitude: number;
      longitude: number;
      count: number;
      maxCount: number;
      countryName: string;
    };
    className: 'lowRiskCustomMarker' | 'highRiskCustomMarker';
  }) => {
    const { countryName, count, maxCount, latitude, longitude } = marker;
    const iconSize = Math.max(30, (count * maxMarkerSize) / maxCount);
    const icon = L.divIcon({
      className,
      html: `<div><span>${count}</span></div>`,
      iconSize: [iconSize, iconSize],
    });

    return (
      <Marker icon={icon} position={[latitude, longitude]} key={JSON.stringify([latitude, longitude])}>
        <Tooltip direction="top">
          <div>
            <i>{countryName}</i>
          </div>
        </Tooltip>
      </Marker>
    );
  };

  if (error || highRiskCountriesError) {
    console.error(error);
    return <div>failed to load</div>;
  }
  return (
    <CardContent>
      {isLoading && <PageLoader />}
      <StyledMapContainer
        id="map"
        center={[39.8397, 24.0297]}
        zoom={2}
        zoomControl={true}
        maxZoom={6}
        scrollWheelZoom={false}
        whenReady={() => {
          const mapElement = document.getElementById('map');
          mapElement.setAttribute('data-scroll-instructions', scrollInstructions);
        }}
      >
        <MapScrollWheelZoomNotifier />
        <TileLayer
          url={`https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
          attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />
        {filteredCountries.map(({ countryCode, countryName, count, latitude, longitude }, index) => {
          const isHighRisk = highRiskCountries?.includes(countryCode);
          return (
            <MarkerWithToolTip
              marker={{ countryName, count, maxCount: appsMaxCount, latitude, longitude }}
              className={isHighRisk ? 'highRiskCustomMarker' : 'lowRiskCustomMarker'}
              key={index}
            />
          );
        })}
      </StyledMapContainer>
    </CardContent>
  );
}

const StyledMapContainer = styled(MapContainer)`
  height: 517px;
  width: 100%;
`;
